@font-face {
  font-family: "DIN";
  src: url("assets/fonts/DIN.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "DIN";
  src: url("assets/fonts/DIN Medium.ttf");
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "DIN";
  src: url("assets/fonts/DIN Bold.ttf");
  font-style: bold;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "DIN";
  src: url("assets/fonts/DIN Black.ttf");
  font-style: bolder;
  font-weight: 700;
  font-display: swap;
}

body {
  /* background-color: #F5F3F6; */
  background-color: #F0EEF1;
}

* {
  font-family: "DIN", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.collapsable {
  height: auto;
  animation: collapsable-animation linear 1s;
}

/* TODO: collapse-animation */
@keyframes collapsable-animation {
  0% {
    max-height: 0px;
  }

  100% {
    max-height: 300px;
  }
}

@keyframes slowRotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* for Baremetrics cancel flow */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.bm-cancel {
  /* Existing styles */
  animation: fadeIn 0.3s ease forwards;
  /* Adjust timing as necessary */
}

.bm-background {
  opacity: 0.8 !important;
}

.brandListItemModalComponent:hover {
  background-color: #f5f5f5;
}

.brandListItemModalComponent:last-child {
  border-bottom: none;
}

.generalPurpleButtonElement:hover {
  background-color: #8800FF;
  color: #ffffff;
}

.generalPurpleButtonElement .MuiButton-label {
  white-space: nowrap;
}

.optionsContainerOuter {
    border: 1px solid #C0BDC4;
}

.optionsContainerOuter:hover {
    border: 1px solid black !important;
}

@keyframes modalFadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes modalContentFadeIn {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.debugboxFilterKeyInput::placeholder,
.debugboxFilterValueInput::placeholder {
    color: rgba(255, 255, 255, 0.4);
}