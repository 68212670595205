@media (max-width: 1200px) {
  .swiper {
    margin-top: -30px;
    height: 100%;
  }
  .swiper-wrapper {
    padding-top: 48px;
    min-height: auto;
    height: 100% !important;
  }

  .swiper-slide {
    height: 100% !important;
  }
}
@media (max-width: 768px) {
  .swiper {
    margin-top: -30px;
  }
  .swiper-wrapper {
    padding-top: 48px;
    min-height: auto;
    height: 100% !important;
  }

  .swiper-slide {
    height: 100% !important;
  }

}
@media (max-width: 255px) {
  .swiper{
    height: max-content !important;
  }
}

.swiper-button-next  {
  margin-top: 0px !important;
  right: 0px !important;
  left: calc(100% - 40px) !important;
  bottom: 0px !important;
  top: 0px !important;
  height: 24px !important;
  width: 24px !important;
  content: url(assets/images/chevronRight.svg);
}

.swiper-button-prev  {
  margin-top: 0px !important;
  margin-right: 16px;
  right: 0px !important;
  left: calc(100% - 70px) !important;
  bottom: 0px !important;
  top: 0px !important;
  height: 24px !important;
  width: 24px !important;
  content: url(assets/images/chevronLeft.svg);
  
}

.swiper-button-prev::after >  path {
    stroke: '#8EC3FF';
}

