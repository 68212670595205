@tailwind base;
@tailwind components;

@tailwind utilities;

/* added to make sure recharts can show numbers properly */
.recharts-surface {
  overflow: visible;
}

html, body {
  /* height: 100%; */
  width: 100%;
}

.instagram {
  background: #f09433;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
}

.spotify {
  background: #1db954;
}


.active-stroke path {
  stroke: #80f;
}


@media (max-width: 768px) {
  .h-calc {
    height: calc(100vh - 80px);
  }
}
.mh-calc {
  height: calc(100vh - 158px - 100px);
}

.bottom-24 {
  bottom: 6rem !important;
}
.swiper-pagination-bullet-active {
  background: #07c806 !important;
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 22px !important;
}

/* .lg\:500 {
  height: 500px;
} */

.swiper-button-next,
.swiper-button-prev {
  color: #07c806 !important;
}
@media (max-width: 768px) {
  .nav-link path {
    fill: gray;
  }
}
@media (min-width: 1024px) {
  .active-stroke path {
    stroke: white;
  }

  .lg\:h-500 {
    height: 600px !important;
  }
}
@media (min-width: 1200px) {
  .lg\:h-500 {
    height: 550px !important;
  }
  .md\:d-flex {
    display: flex !important;
  }
}

@layer components {
  .body-main {
    @apply flex justify-between items-center h-calc bg-background-100;
  }
  .body-wrapper {
    @apply bg-background-100 pb-0 lg:pb-20;
  }
  .btn-primary {
    @apply p-2 bg-primary w-full lg:w-64 text-white text-2xl rounded-full mt-4;
  }
  .btn-secondary {
    @apply p-2 bg-white w-full lg:w-64 text-primary text-2xl rounded-full mt-4 border border-primary;
  }
  .sy-card {
    @apply p-4 px-2 lg:px-4 flex items-center justify-between border-b border-gray-200 w-full;
  }
}

.artist-img {
  @apply relative w-10 h-10;
}
.artist-img img {
  @apply w-10 h-10 object-cover overflow-hidden rounded-md relative;
  /* min-width: 40px; */
}
.instagram-post-container {
  @apply bg-black rounded-md relative h-40 w-40;
}
.instagram-post-img {
  @apply h-40 w-40 object-contain overflow-hidden rounded-md relative;
  /* min-width: 40px; */
}
.con-icon img {
  @apply absolute w-5 h-5 -right-1 -bottom-2;
}

.social-img {
  @apply w-4 h-4 object-cover rounded-full flex-none;
}
.social-text {
  @apply flex-auto;
}

.sy-card a {
  @apply no-underline;
}

.artist-list {
  @apply pb-5;
}

.bg-green {
  @apply bg-primary border-4;
}

.w-35 {
  width: 35px;
  height: 35px;
}
.container span {
  @apply font-normal pl-2;
}
.number {
  @apply rounded-full flex items-center justify-items-center w-10 h-10 bg-primary;
}
.number p {
  @apply text-white pt-1;
}

.hr-bline {
  @apply border-b border-background-100;
}

/* Facebook */
.btn.btn-facebook {
  @apply border border-socialColor-facebook bg-socialColor-facebook w-full px-4 text-white py-2 rounded-full md:mx-20 mx-2 mx-auto;
}
.btn.btn-facebook:hover {
  @apply border border-socialColor-facebook bg-socialColor-facebook text-white shadow-none;
}

.btn-i {
  @apply py-1 px-5  float-left;
}

/* Customize the label (the container) */
.container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  @apply bg-background-100 border border-background-100 rounded-full;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  @apply bg-primary;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  @apply bg-primary;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 8px;
  top: 3px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

button:disabled {
  @apply bg-background-100 border border-background-100 text-gray-400;
}

.card-body {
  @apply mx-auto bg-white mt-0 lg:mt-2;
}
@media (max-width: 1920px) {
  .card-body {
    max-width: 800px;
  }
}

@media (max-width: 981px) {
  .card-body {
    width: 100%;
    margin: auto;
  }
}

/* @media (max-width: 1920px) {
  .card-body{
    height:fit-content; 
  }
}
@media (max-width: 768px) {
  .card-body{
    height:auto; 
  }
} */
.card-content {
  @apply bg-white shadow-none flex flex-col justify-between;
  
}
.card-head {
  @apply bg-white lg:bg-primary w-full p-5 text-center border-b border-gray-200;
}

.onboarding img {
  @apply w-full h-auto object-fill;
}
.onboarding-title {
  @apply text-left lg:text-center mt-8 mb-2 text-3xl font-medium;
}

.onboarding-para {
  @apply text-left lg:text-center mb-8;
}
.middle-wrapper {
  min-height: 300px;
}
.main-body {
  @apply pt-0;
  /* padding-bottom: 75px; */
}
/* dash-Header */

@media (min-width: 1024px) {
  .pb-75px {
    padding-bottom: 75px;
  }
}

.dash-header {
  @apply p-4 block lg:hidden;
  background: #2b2134;
}

.dash-header h3 {
  @apply text-white pb-2 text-2xl;
}

.bg-green {
  background: #00ab00;
  border-radius: 4px;
}

.text-green {
  color: #00ab00;
}

.bg-purple {
  background: #6200b9;
  border-radius: 4px;

}

ul li a {
  @apply text-black lg:text-white;
}

.border-c4c4c4 {
  border: 1px solid #c4c4c4;
}

.br-10px {
  border-radius: 10px;
}

.br-20px {
  border-radius: 20px;
}

.radio-toolbar {
  display: flex;
  border: 1px solid #80f;
  border-radius: 11px;
}

.radio-toolbar.rounded-none {
  border-radius: 0px;
}

.radio-toolbar input[type="radio"] {
  display: none;
}

.radio-toolbar label {
  background-color: #fff;
  padding: 0.5rem;
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.75rem;
  --tw-text-opacity: 1;
  cursor: pointer;
  color: #80f;
}

.radio-toolbar input[type="radio"]:checked + label {
  background-color: rgba(136, 0, 255, 1);
  padding: 0.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, 1);
}

.number-blk {
  @apply rounded-full bg-background-200 w-10 h-10 items-center text-white justify-center flex;
}
@responsive {
  .number-blk.added {
    @apply rounded-full bg-primary w-10 h-10 items-center text-white justify-center flex !important;
  }
}

/* Loading css */
.svg-loader {
  @apply flex relative justify-center content-around w-9 h-9;
}
.loader-svg {
  @apply absolute left-0 right-0 top-0 bottom-0;
  fill: none;
  strokeWidth: 5px;
  stroke-linecap: round;
  stroke: #00ab00;
}
.loader-svg.bg {
  strokeWidth: 8px;
  stroke: rgb(207, 205, 245);
}
.animate {
  stroke-dasharray: 242.6;
  animation: fill-animation 1s cubic-bezier(1, 1, 1, 1) 0s infinite;
}

@keyframes fill-animation {
  0% {
    stroke-dasharray: 40 242.6;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 141.3;
    stroke-dashoffset: 141.3;
  }
  100% {
    stroke-dasharray: 40 242.6;
    stroke-dashoffset: 282.6;
  }
}

.cta-wrapper {
  @apply lg:static lg:bottom-0 bottom-32 w-full;
}

/* slider */

.slick-dots {
  bottom: 60px;
}
.slick-dots li.slick-active button:before {
  @apply text-primary;
}
.slick-dots li button:before {
  font-size: 10px;
  opacity: 0.25;
}
.slick-dots li button:hover {
  @apply text-primary;
}
.slick-next:before,
.slick-prev:before {
  @apply text-primary;
  font-size: 24px;
}
@media (max-width: 981px) {
  .slick-next,
  .slick-prev {
    display: none !important;
  }

  nav.flex.border-b.border-gray-300 button {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.onboard-overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.mt-0pt375 {
  margin-top: 0.375rem;
}