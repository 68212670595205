.customDatePickerContainer,
.customDatePickerContainer>div.react-datepicker-wrapper,
.customDatePickerContainer>div>div.react-datepicker__input-container .customDatePickerContainer>div>div.react-datepicker__input-container input {
    width: 100%;
    padding-top: 6px;
}

.react-datepicker__input-container input {
    text-align: left !important;
    outline: none;
    cursor: pointer;
    caret-color: transparent;
}